<div class="form-title">Page setup</div>
<div class="form-dialog">

    <mat-form-field class="form-input">
        <mat-label translate>page-setup.Format</mat-label>
        <input matInput [(ngModel)]="page.format">
    </mat-form-field>

    <div></div>

    <mat-form-field class="form-input">
        <mat-label translate>page-setup.Orientation</mat-label>
        <input matInput [(ngModel)]="page.orientation">
    </mat-form-field>

    <div></div>

    <mat-form-field class="form-input">
        <mat-label translate>page-setup.MarginTop</mat-label>
        <input matInput [(ngModel)]="page.border.top">
    </mat-form-field>

    <div></div>

    <mat-form-field class="form-input">
        <mat-label translate>page-setup.MarginBottom</mat-label>
        <input matInput [(ngModel)]="page.border.bottom">
    </mat-form-field>

    <div></div>

    <mat-form-field class="form-input">
        <mat-label translate>page-setup.MarginLeft</mat-label>
        <input matInput [(ngModel)]="page.border.left">
    </mat-form-field>

    <div></div>

    <mat-form-field class="form-input">
        <mat-label translate>page-setup.MarginRight</mat-label>
        <input matInput [(ngModel)]="page.border.right">
    </mat-form-field>

    <div></div>

    <mat-form-field class="form-input">
        <mat-label translate>page-setup.HeaderHeight</mat-label>
        <input matInput [(ngModel)]="page.header.height">
    </mat-form-field>

    <div></div>

    <mat-form-field class="form-input">
        <mat-label translate>page-setup.FooterHeight</mat-label>
        <input matInput [(ngModel)]="page.footer.height">
    </mat-form-field>

    <div class="spacer"></div>

</div>

<div>
    <span class="action_cancel" (click)="onDismiss()" translate>confirmation.Cancel</span>
    <span class="action_ok" (click)="onConfirm()" translate>confirmation.Ok</span>
</div>
