import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})


export class ImageService {
  private api = "image";
  constructor(
    private http:HttpClient
  ) {}

  getAll() {
    return this.http.get<any>(`${environment.docgenApiUrl}/${this.api}`)
      .pipe(map(temp => {
        return temp;
      }))
  }
}
