import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { DocumentTemplateRoutes } from './document-template.routing';
import { TemplateTypeOverviewComponent } from './template-type-overview/template-type-overview.component';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { HeaderFooterOverviewComponent } from "./header-footer-overview/header-footer-overview.component";
import { HeaderEditorComponent } from "./header-editor/header-editor.component";
import { FooterEditorComponent } from "./footer-editor/footer-editor.component";
import { TemplateOverviewComponent } from "./template-overview/template-overview.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TableViewComponent } from "../../shared/components/table-view/table-view.component";
import { TreeViewComponent } from "../../shared/components/tree-view/tree-view.component";
import { ConfirmDialogComponent } from "../../shared/components/confirm-dialog/confirm-dialog.component";
import { CreateTemplateDialogComponent } from "../document-template/create-template-dialog/create-template-dialog.component";
import { CreateTemplateTypeDialogComponent } from "../document-template/create-template-type-dialog/create-template-type-dialog.component";
import { PageSetupDialogComponent } from "../document-template/page-setup-dialog/page-setup-dialog.component";
import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { ConvertTimezonePipe } from "src/app/pipes/convert-timezone.pipe";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {DocumentViewComponent} from "./document-view/document-view.component";
import { MaterialModule } from 'src/app/shared/module/material.module';

// COMPONENTS
@NgModule({
  imports: [
    CommonModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(DocumentTemplateRoutes),
    TranslateModule.forChild({
      isolate: false,
    }),
    MaterialModule,
    NgxMatSelectSearchModule
  ],
  declarations: [
    TemplateTypeOverviewComponent,
    TemplateEditorComponent,
    HeaderFooterOverviewComponent,
    HeaderEditorComponent,
    FooterEditorComponent,
    DocumentViewComponent,
    TemplateOverviewComponent,
    TableViewComponent,
    TreeViewComponent,
    ConfirmDialogComponent,
    CreateTemplateDialogComponent,
    CreateTemplateTypeDialogComponent,
    PageSetupDialogComponent,
    ConvertTimezonePipe,
  ],
  exports: [ConvertTimezonePipe, MaterialModule],
})
export class DocumentTemplateModule {}
