import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor } from "@angular/common/http";
import { HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { ToastComponent } from "../shared/components/toast/toast.component";
import { tap } from "rxjs/operators";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private toast: ToastComponent) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let currentRole = localStorage.getItem("currentRole");

    const clonedRequest = req.clone({
      headers: req.headers
        .set("CurrentRole", currentRole)
        .set("origin_path", this.replaceOriginPath(req))
        .set("X-C4P-CID", this.generateRandomValue())
        .set("Accept-Language", "en"),
      withCredentials: true,
    });

    return next.handle(clonedRequest).pipe(
      tap(
        (evt) => {
          if (evt instanceof HttpResponse) {
            if (evt.body && evt.body.toast) {
              this.toast.setMessage(
                evt.body.toast.message,
                evt.body.toast.type
              );
            }
          }
        },
        (error) => {
          if (error.error.error) {
            this.toast.setMessage(error.error.error.message, "danger");
          } else {
            this.toast.setMessage(error.error.message, "danger");
          }
        }
      )
    );
  }

  private generateRandomValue(): string {
    const alphaNums =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";

    for (let i = 0; i < 16; i++) {
      const rand = Math.random();
      const index = Math.floor(rand * alphaNums.length);
      result += alphaNums.substr(index, 1);
    }

    return result;
  }

  private replaceOriginPath(req: HttpRequest<any>) {
    return `${
      req.headers.get("origin_path")
        ? req.headers.get("origin_path")
        : "/docgen"
    }`;
  }
}
