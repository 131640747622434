import { Routes } from '@angular/router';


//COMPONENTES
import { TemplateTypeOverviewComponent } from './template-type-overview/template-type-overview.component';
import { TemplateEditorComponent } from './template-editor/template-editor.component';
import { TemplateOverviewComponent } from './template-overview/template-overview.component';
import { HeaderFooterOverviewComponent } from "./header-footer-overview/header-footer-overview.component";
import { PermissionRouterGuard } from "src/app/shared/services/permission-router-guard/permission-router-guard.service";

export const DocumentTemplateRoutes: Routes = [
  {
    path: "",
    // component:SideBarComponent,
    children: [
      {
        path: "template-editor",
        component: TemplateEditorComponent,
        data: {
          title: "TemplateEditor",
        },
        // canActivate: [AppAuthGuard],
      },
      {
        path: "template-editor/:id",
        component: TemplateEditorComponent,
        // canActivate: [AppAuthGuard],
      },
      {
        path: "image",
        component: TemplateEditorComponent,
        // canActivate: [AppAuthGuard],
      },
      {
        path: "template-type",
        component: TemplateTypeOverviewComponent,
        data: {
          title: "TemplateType",
        },
        // canActivate: [AppAuthGuard],
      },
      {
        path: "template-overview",
        component: TemplateOverviewComponent,
        data: {
          title: "TemplateOverview",
        },
        // canActivate: [AppAuthGuard],
      },
      {
        path: "global-settings",
        component: HeaderFooterOverviewComponent,
        children: [
          {
            path: "headers",
            component: HeaderFooterOverviewComponent,
            data: {
              permission: "docgen:list|docgen:manage",
              pageType: "header",
              title: "Headers",
            },
            canActivate: [PermissionRouterGuard],
          },
          {
            path: "footers",
            component: HeaderFooterOverviewComponent,
            data: {
              pageType: "footer",
              title: "Footers",
              permission: "docgen:list | docgen:manage",
            },
            canActivate: [PermissionRouterGuard],
          },
        ],
      },
    ],
  },
];
