import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.scss'],
})
export class DocumentViewComponent implements OnInit {
  documentUrl: any;
  document: any;

  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DocumentViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log(data);
    this.documentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      data.documentUrl,
    );
    this.document = data.document;
  }

  ngOnInit(): void {}
}
