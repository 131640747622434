import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'convertTimezone'
})
export class ConvertTimezonePipe implements PipeTransform {

  transform(value: any): any {
    const timezone = localStorage.getItem('timezone');
    return moment(value).tz(timezone).format('DD/MM/yyyy HH:mm')
  }

}

