<div>
    <div id="inlineFields">
        <div class="tool-bar-container">
            <div class="tool-bar">
                <a (click)="updateHeaderAndFooter()" class="tool-bar-item" matTooltip="{{'actions.Edit' | translate}}">
                    <mat-icon matPrefix class="tool-bar-icon">edit</mat-icon>
                </a>
                <a (click)="pageSetup()" class="tool-bar-item" matTooltip="{{'actions.Setup' | translate}}">
                    <mat-icon matPrefix class="tool-bar-icon">equalizer</mat-icon>
                </a>
                <!--a (click)="generateHTML()" class="tool-bar-item" matTooltip="Generate HTML" hidden>
                    <mat-icon matPrefix class="tool-bar-icon">style</mat-icon>
                </a-->
                <a (click)="previewPDF()" class="tool-bar-item" matTooltip="{{'actions.Preview' | translate}}">
                    <mat-icon matPrefix class="tool-bar-icon">visibility</mat-icon>
                </a>
                <!--a id="saveButton" (click)="onSubmit()" class="tool-bar-item" matTooltip="Save" hidden>
                    <mat-icon matPrefix class="tool-bar-icon">save</mat-icon>
                </a-->
                <a *ngIf="template.status=='active'" (click)="changeStatus('draft')" class="tool-bar-item"
                    matTooltip="{{'actions.Draft' | translate}}">
                    <mat-icon matPrefix class="tool-bar-icon">gesture</mat-icon>
                </a>
                <a *ngIf="template.status=='draft'" (click)="changeStatus('active')" class="tool-bar-item"
                    matTooltip="{{'actions.Activate' | translate}}">
                    <mat-icon matPrefix class="tool-bar-icon">check_circle</mat-icon>
                </a>
            </div>
        </div>

        <div class="edit-divider">
            <p></p>
            <div>
                <span class="edit-align-left">
                </span>
                <span class="edit-align-right">
                    <span class="label-status-active" *ngIf="template.status === 'active'">ACTIVE</span>
                    <span class="label-status-draft" *ngIf="template.status === 'draft'">DRAFT</span>
                </span>
            </div>

            <div>
                <span class="edit-align-left">
                    <mat-form-field class="form-input">
                        <mat-label translate>template-editor.TemplateName</mat-label>
                        <input matInput [(ngModel)]="template.name" (keyup.enter)="onSubmit()">
                    </mat-form-field>
                </span>
                <span class="edit-align-right">
                    <span translate class="edit-label">template-editor.LastUpdate</span>
                    <span class="edit-value">{{template.updated_at}}</span>
                </span>
            </div>
        </div>
        <!-- <mat-form-field style="width: 90%;">
            <mat-label>JSON</mat-label>
            <textarea matInput [(ngModel)]="jsonvalues"></textarea>
        </mat-form-field> -->
    </div>
</div>

<div class="t-main">
    <div class="form-padding">
        <div id="parent">
            <div class="A4">
                <div [froalaEditor]='options' [(froalaModel)]="content" (froalaInit)="initFroala($event)">
                </div>
            </div>
            <span style="width:15px"></span>
            <div class="editor-variables-container">
                <div class="form-padding">
                    <div class="form-title">Placeholders</div>
                    <mat-slide-toggle class="toggle-switch" (change)="conditionalToggled = !conditionalToggled"
                        [checked]="conditionalToggled">
                        Toggle conditional
                    </mat-slide-toggle>
                </div>
                <div class="editor-variables">
                    <app-tree-view [isEditMode]=false [conditionalToggled]="conditionalToggled"
                        (messageEvent)="receiveTreeEvent($event)"></app-tree-view>
                </div>
            </div>
        </div>
    </div>
</div>
