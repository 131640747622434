import { Component, OnInit } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CreateTemplateDialogComponent } from "../create-template-dialog/create-template-dialog.component"

@Component({
  selector: "app-template-overview",
  templateUrl: "./template-overview.component.html",
  styleUrls: ["./template-overview.component.scss"],
})
export class TemplateOverviewComponent implements OnInit {
  public columns = [
    { id: "name", name: "template.Name" },
    { id: "template_type_name", name: "template.TemplateType" },
    {
      id: "created_at",
      name: "template.Created",
      type: "date",
      format: "dd/MM/yyyy",
    },
    { id: "created_by", name: "template.CreatedBy" },
    {
      id: "updated_at",
      name: "template.Updated",
      type: "date",
      format: "dd/MM/yyyy HH:mm",
    },
    { id: "updated_by", name: "template.UpdatedBy" },
    { id: "status", name: "template.Status" },
  ];
  public api = "template-overview";
  public editRoute = "/template-editor";
  public name = "template";

  constructor(
    public templateService: TemplateService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {}

  public createTemplate() {
    const dialogRef = this.dialog.open(CreateTemplateDialogComponent, {
      data: {},
      panelClass: "custom-modalbox",
      backdropClass: "cdk-overlay-transparent-backdrop",
      hasBackdrop: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.router.navigate([this.editRoute, dialogResult]);
      }
    });
  }
}
