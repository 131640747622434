<mat-sidenav-container class="app-container">
    <!-- left menu -->
    <mat-sidenav #drawer mode="side" opened role="navigation" class="app-side-bar">
        <app-side-bar (isCollapsed)="onToogle(drawer)"
                      [isCollapsedOrExpand]="isCollapsedOrExpand"
        ></app-side-bar>
    </mat-sidenav>

    <!-- top bar and content -->
    <mat-sidenav-content>
        <!-- content -->
        <div class="t-container">
            <div class="toolbar">
                <mat-toolbar>
                    <mat-toolbar-row class="app-top-bar">
                        <div class="collapse-expand">
              <span type="button" aria-label="Toggle sidenav" class="btn-icon" mat-icon-button
                    (click)="onToogle(drawer)">
                <ng-container *ngIf="isCollapsedOrExpand">
                  <span class="navbar-chevron-button"> > </span>
                </ng-container>
              </span>
                        </div>
                        <!-- <app-top-bar></app-top-bar> -->
                        <div class="app-top-bar-items">

                            <div class="flag">
                                <app-language-picker></app-language-picker>
                            </div>
                        </div>

                    </mat-toolbar-row>
                </mat-toolbar>
            </div>
            <div class="t-content">
                <div class="app-content-bar">
                    <app-toast [message]="toast.message"></app-toast>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>

    </mat-sidenav-content>


</mat-sidenav-container>
