<div class="tool-bar-container">
  <div class="tool-bar">
    <a (click)="createTemplate()" class="tool-bar-item" matTooltip="{{'actions.Add' | translate}}">
      <mat-icon matPrefix class="tool-bar-icon">note_add</mat-icon>
    </a>
  </div>
</div>

<app-table-view
    [name]=name [columns]=columns
    [api]=api [additionalParams]=additionalParams
    [editDialog]="hasPermission() ? editTemplate.bind(this) : null"
    [deleteService]="hasPermission() ? globalService.delete.bind(globalService) : null"
    [copyService]="hasPermission() ? globalService.copy.bind(globalService) : null"
    [changeStatusService]="hasPermission() ? globalService.changeStatus.bind(globalService) : null"
></app-table-view>

