<div class="padding">
  <mat-form-field class="table-search">
    <mat-icon matPrefix class="table-search-icon">search</mat-icon>
    <mat-label class="table-search-text" translate>labels.Search</mat-label>
    <input matInput (input)="onSearch($event)">
  </mat-form-field>
</div>

<div class="padding">
  <mat-table class="table" [dataSource]="data" matSort (matSortChange)="handleSort($event)">
    <ng-container matColumnDef="{{column.id}}" *ngFor="let column of columns">
      <div *ngIf="column.type=='date'">
        <mat-header-cell *matHeaderCellDef mat-sort-header="{{column.id}}" class="table-header"> {{column.name |
          translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element" class="table-cell"> {{ element[column.id] | convertTimezone }}
        </mat-cell>
      </div>

      <div *ngIf="column.id!='status'">
        <mat-header-cell *matHeaderCellDef mat-sort-header="{{column.id}}" class="table-header"> {{column.name |
          translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element" class="table-cell"> {{element[column.id]}} </mat-cell>
      </div>

      <div *ngIf="column.id=='status'">
        <mat-header-cell *matHeaderCellDef mat-sort-header="{{column.id}}" class="table-header"> {{column.name |
          translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span class="label-status-active" *ngIf="element[column.id] === 'active'">ACTIVE</span>
          <span class="label-status-draft" *ngIf="element[column.id] === 'draft'">DRAFT</span>
        </mat-cell>
      </div>
    </ng-container>

    <ng-container matColumnDef="icon_action" stickyEnd>
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a *ngIf="editRoute" (click)="openEdit(element)" matTooltip="{{'actions.Edit' | translate}}">
          <mat-icon matPrefix class="table-action-icons">create</mat-icon>
        </a>
        <a *ngIf="editDialog" (click)="openEditDialog(element)" matTooltip="{{'actions.Edit' | translate}}">
          <mat-icon matPrefix class="table-action-icons">create</mat-icon>
        </a>
        <a *ngIf="deleteService" (click)="deleteAction(element)" matTooltip="{{'actions.Delete' | translate}}">
          <mat-icon matPrefix class="table-action-icons">delete</mat-icon>
        </a>
        <a *ngIf="copyService" (click)="copyAction(element)" matTooltip="{{'actions.Copy' | translate}}">
          <mat-icon matPrefix class="table-action-icons">library_books</mat-icon>
        </a>
        <a *ngIf="changeStatusService && element.status==='draft'" (click)="changeStatus(element,'active')"
          matTooltip="{{'actions.Activate' | translate}}">
          <mat-icon matPrefix class="table-action-icons">check_circle</mat-icon>
        </a>
        <a *ngIf="changeStatusService && element.status==='active'" (click)="changeStatus(element,'draft')"
          matTooltip="{{'actions.Draft' | translate}}">
          <mat-icon matPrefix class="table-action-icons">gesture</mat-icon>
        </a>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <mat-paginator class="table-paginator" [length]="totalRows" [pageSize]="pageSize" [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions" (page)="handlePage($event)">
  </mat-paginator>
</div>