<div *ngIf="message.body" class="alert alert-{{message.type}} alert-dismissible" role="alert">
  <div *ngIf="message.type=='success'"> 
    <mat-icon matPrefix class="alert-icon">check_circle_outline</mat-icon>     
    {{message.body | translate}}
  </div>
  <div *ngIf="message.type=='danger'"> 
    <mat-icon matPrefix class="alert-icon">error_outline</mat-icon>     
    {{message.body | translate}}
  </div> 
</div>
