import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { TemplateType } from "src/app/models/template-type";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TemplateTypeService {
  private api = "template-type";

  constructor(private http: HttpClient) {}

  delete(id: any) {
    return this.http
      .delete<any>(`${environment.docgenApiUrl}/${this.api}/${id}`)
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  copy(id: any) {
    return this.http
      .post<any>(`${environment.docgenApiUrl}/${this.api}/${id}`, null)
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  getAll() {
    return this.http.get<any>(`${environment.docgenApiUrl}/${this.api}`).pipe(
      map((temp) => {
        return temp;
      })
    );
  }

  get(id: any) {
    return this.http
      .get<any>(`${environment.docgenApiUrl}/${this.api}/${id}`)
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  create(templateType: TemplateType) {
    return this.http
      .post<any>(`${environment.docgenApiUrl}/${this.api}`, { templateType })
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  update(id: any, templateType: TemplateType) {
    return this.http
      .put<any>(`${environment.docgenApiUrl}/${this.api}/${id}`, {
        templateType,
      })
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }
}
