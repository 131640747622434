<div class="main-logo">
  <div class="collapse-expand" *ngIf="!isCollapsedOrExpand">
    <span type="button" aria-label="Toggle sidenav" mat-icon-button (click)="collapseOrExpand()">
      <ng-container *ngIf="isCollapsedOrExpand">
        <span class="navbar-chevron-button"> > </span>
      </ng-container>
      <ng-container *ngIf="!isCollapsedOrExpand">
        <span class="navbar-chevron-button"> < </span>
      </ng-container>
    </span>
  </div>
    <div class="logo">
    <img src="/assets/img/svg/logo.svg" alt="" class="img-logo">
  </div>
</div>
<mat-nav-list>
  <!-- side menu -->
    <mat-expansion-panel class="side-menu" [expanded]="true" hideToggle="true" #title>
      <mat-expansion-panel-header class="side-menu-expansion-menu-header">
        <mat-panel-title class="side-menu-title">
          <span translate>side-bar.DocumentTemplate</span>
        </mat-panel-title>
        <mat-panel-description class="side-menu-arrow">
          <mat-icon class="menu-icon">lists</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div [routerLink]="['document-template/template-overview']" routerLinkActive="side-menu-item-selected" class="side-menu-item">
        <a translate>side-bar.TemplateOverview</a>
      </div>
      <div [routerLink]="['document-template/template-type']" routerLinkActive="side-menu-item-selected" class="side-menu-item">
        <a translate>side-bar.TemplateType</a>
      </div>
    <mat-expansion-panel class="side-menu-submenu" hideToggle="true">
      <mat-expansion-panel-header  class="side-menu-expansion-menu-header">
        <mat-panel-title class="side-menu-title">
          <span translate>side-bar.GlobalSettings</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="hasPermission()" [routerLink]="['document-template/global-settings/headers']" routerLinkActive="side-menu-item-selected" class="side-menu-sub-item">
        <a translate>side-bar.Headers</a>
      </div>
      <div *ngIf="hasPermission()" [routerLink]="['document-template/global-settings/footers']" routerLinkActive="side-menu-item-selected" class="side-menu-sub-item">
        <a translate>side-bar.Footers</a>
      </div>
    </mat-expansion-panel>
      
    </mat-expansion-panel>

  <!-- copyright -->
  <div class="copyright">
    <span translate>gulia.Copyright</span>
  </div>
</mat-nav-list>

