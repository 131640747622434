import {Component} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {KeycloakService} from "keycloak-angular";
import {Router} from "@angular/router";
import {AuthenticationService} from "./services";
import {ToastComponent} from "./shared/components/toast/toast.component";
import {MatSidenav} from "@angular/material/sidenav";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    title = "elise-docgen-frontend";
    isLoggedIn: boolean = true;
    accessForbiden: boolean = false;
    isCollapsedOrExpand = false;

    constructor(
        private translate: TranslateService,
        private keycloakService: KeycloakService,
        private router: Router,
        public authService: AuthenticationService,
        public toast: ToastComponent,
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry,
    ) {
        translate.setDefaultLang("en");
        this.matIconRegistry.addSvgIcon('flag-en', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/svg/gb.svg'));
        this.matIconRegistry.addSvgIcon('flag-nl', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/img/svg/nl.svg'));
    }

    ngOnInit() {
        this.keycloakService.getToken().then((data) => {
            // this.authService.login(data).subscribe(() => {
            // }
            // );
        });
    }

    // Changing language on button press
    changeLanguage(language: string) {
        this.translate.setDefaultLang(language);
    }

    onToogle(drawer: MatSidenav) {
        drawer.toggle();
        this.isCollapsedOrExpand = !this.isCollapsedOrExpand;
    }
}
