// router.service.ts
import { Injectable } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { filter, map, mergeMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class RouterService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  getPageTitle() {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === "primary"),
      mergeMap((route) => route.data)
    );
  }
}
