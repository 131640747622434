<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree" [ngClass]="conditionalToggled ? 'conditional-toggled': ''">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li>
            <span class="tree-node">
                <mat-checkbox [checked]=node.visible *ngIf="isEditMode" (change)="toggleLeafVisible(node)"></mat-checkbox>
                <mat-icon class="tree-node-icon property-icon">link</mat-icon>
                <button class="tree-leaf-label" (click)="onLeafClick(node)" [style.color]="conditionalToggled ? '#141414' : ''">{{node.name}}</button>
            </span>
        </li>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li>
            <mat-checkbox *ngIf="isEditMode" 
                (change)="toggleNodeVisible(node)"
                [checked]="checkAllSelected(node)"
                [indeterminate]="checkPartialSelected(node)">
            </mat-checkbox>

            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name" class="tree-node-toggle-button">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>

            <span *ngIf="node.type==='Array'">
                <mat-icon class="tree-node-icon array-icon">filter_none</mat-icon>
                <button class="tree-array-label" (click)="onArrayClick(node)">{{node.name}}</button>
            </span>
            <span *ngIf="node.type==='Object'">
                <mat-icon class="tree-node-icon object-icon">list_alt</mat-icon>
                <button class="tree-node-label" [disabled]="!conditionalToggled && !isEditMode" (click)="onLeafClick(node)">{{node.name}}</button>
            </span>

            <ul [class.tree-invisible]="!treeControl.isExpanded(node)">
                <ng-container matTreeNodeOutlet></ng-container>
            </ul>
        </li>
    </mat-nested-tree-node>
</mat-tree>



<!-- <mat-form-field>
    <input matInput placeholder="Search" (input)="filterChanged($event.target.value)">
</mat-form-field>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
            (change)="checklistSelection.toggle(node);">{{node.item}}</mat-checkbox>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>
        <mat-checkbox [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
            (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
    </mat-tree-node>
</mat-tree> -->