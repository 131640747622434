<form [formGroup]="templateForm" (ngSubmit)="onConfirm()">
<div>
    <div class="form-title" *ngIf="isEdit" translate>actions.UpdateTemplate</div>
    <div class="form-title" *ngIf="!isEdit" translate>actions.CreateNewTemplate</div>

    <div class="form-content">
        <div class="row-gap">
            <mat-form-field class="form-input">
                <mat-label translate>template-editor.TemplateName</mat-label>
                <input matInput formControlName="templateName">
            </mat-form-field>

            <mat-form-field class="form-input">
                <mat-label>Type</mat-label>
                <mat-select  formControlName="selectedType">
                    <mat-option *ngFor="let type of types" [value]="type">{{type.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row-gap">
            <mat-form-field class="flex-grow-row">
                <mat-select formControlName="headerTemplate"  placeholder="{{'labels.Header' | translate}}" >
                    <mat-option>
                        <ngx-mat-select-search [formControl]="headerFilteringControl"
                                               [searching]="searching"
                                               [clearSearchInput]="false"
                                               noEntriesFoundLabel="{{'confirmation.NoMatching' | translate}}"
                                               placeholderLabel="{{'labels.FindHeader' | translate}}"
                        >
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let option of headers" [value]="option">
                        {{ option.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
            <div class="row-gap">

                <mat-form-field class="flex-grow-row">
                    <mat-select formControlName="footerTemplate" placeholder="{{'labels.Footer' | translate}}">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="footerFilteringControl"
                                                   [searching]="searching"
                                                   [clearSearchInput]="false"
                                                   noEntriesFoundLabel="{{'confirmation.NoMatching' | translate}}"
                                                   placeholderLabel="{{'labels.FindFooter' | translate}}"
                            >
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let option of footers" [value]="option">
                            {{ option.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
    </div>
</div>

<div>
    <span class="action_cancel" (click)="onDismiss()" translate>confirmation.Cancel</span>
    <span class="action_ok" type="submit" (click)="onConfirm()" translate>
    <ng-container *ngIf="!isEdit">confirmation.Create</ng-container>
    <ng-container *ngIf="isEdit">confirmation.Update</ng-container>
    </span>
</div>
</form>
