<div class="confirm-header" *ngIf="data?.title">
    <span translate>{{data?.title}}</span>
</div>
<div class="content" *ngIf="data.message">
    <img src="/assets/img/svg/false-icon.svg" alt="" class="img-icon">
    <span class="content-text" translate>{{data.message}}</span>
</div>

<div>
    <span class="action_cancel" (click)="onDismiss()" translate>confirmation.No</span>
    <span class="action_ok" (click)="onConfirm()" translate>confirmation.Yes</span>
</div>
