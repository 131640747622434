import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TableViewService {
  constructor(private http: HttpClient) {}

  getData(
    api,
    pageIndex,
    pagSize,
    sortColumn,
    sortDirection,
    search,
    additionalParams
  ) {
    const paramsText = this.getParams(additionalParams);
    return this.http.get<any>(
      `${environment.docgenApiUrl}/` +
        api +
        `?page=${pageIndex}&limit=${pagSize}&sortColumn=${sortColumn}&sortDirection=${sortDirection}&search=${search}${paramsText}`
    );
  }

  private getParams(additionalParams: any) {
    let entries = [];


    Object.keys(additionalParams).forEach(function (key) {
      entries.push([key, additionalParams[key]]);
    });
    return Object.keys(additionalParams).length > 0
      ? entries.reduce((acc, cur) => {
          return acc + `&${cur[0]}=${cur[1]}`;
        }, "")
      : "";
  }
}
