export class Template {
    id: number;
    tenants_id: number;
    name: string;
    html: string;
    template_id: number;
    status: string;
    template_type_id: number;
    created_by: string;
    updated_at: string;
    page_format: string;
    page_orientation: string;
    page_margin_top: number;
    page_margin_bottom: number;
    page_margin_right: number;
    page_margin_left: number;
    header_height: number;
    footer_height: number;     
    header_html: string;
    footer_html: string;
    header_id?: string;
    footer_id?: string;
}
