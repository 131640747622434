import { TenantConfigurationService } from "src/app/services/tenant-configuration.service";
import { KeycloakService } from 'keycloak-angular';


export function initializeKeycloak(
  keycloak: KeycloakService,
  configService: TenantConfigurationService
) {
  return async () => {
    const configuration = await configService.getKeycloakConfiguration();

    let keycloakConfig: any = {
      url: configuration.keycloakHost,
      realm: configuration.keycloakRealmName,
      clientId: "c4p-employee-portal",
    };

    return keycloak.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: "login-required",
        checkLoginIframe: false,
      },
      bearerExcludedUrls: [],
      bearerPrefix: "Bearer",
    });
  };
}
