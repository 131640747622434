﻿import { Injectable } from "@angular/core";
// import {Observable} from "rxjs/Observable";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
// import "rxjs/add/operator/map";
import { Router } from "@angular/router";
import { KeycloakService } from "keycloak-angular";
import { Location } from "@angular/common";

@Injectable()
export class AuthenticationService {
  loggedUser = [];
  private loginUrl = `${environment.docgenApiUrl}/auth/login`;

  constructor(
    private router: Router,
    private http: HttpClient,
    private location: Location,
    public keycloakService: KeycloakService
  ) {}

  isAuthenticated() {
    let currentUser = localStorage.getItem("currentUser");
    if (currentUser !== null) {
      this.loggedUser = JSON.parse(currentUser);
      return true;
    } else {
      return false;
    }
  }

  login(token: string) {
    // return this.http.post(this.loginUrl,
    //   { token },
    // );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentRole");
    localStorage.removeItem("currentUser");
    this.location.replaceState("/home");
    this.keycloakService.logout();
  }

  logoutIdle(redirectToLogin = true) {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("currentRole");
    this.router.navigate(["/login"]);

    if (redirectToLogin) {
      this.router.navigate(["/login"]);
    }
  }

  // isAdministrator(): boolean {
  //   return localStorage.getItem("currentRole") == RoleEnum.Administrateur;
  // }
}
