import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class VariableService {
  private api = "variable";

  constructor(private http: HttpClient) {}

  generate(data: any) {
    return this.http
      .put<any>(`${environment.docgenApiUrl}/variable`, { data })
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }
}
