import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {PaginationDataModel} from "../models/pagination-data.model";
import {HttpUtils} from "../shared/utils/http.utils";

@Injectable({
  providedIn: "root",
})
export class GlobalService {
  private api = "global";

  constructor(private http: HttpClient) {}

  getHeader(id?: number) {
    return this.http
      .get<any>(`${environment.docgenApiUrl}/${this.api}/header?header_id=${id}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  
  getFooter(id?: number) {
    return this.http
      .get<any>(`${environment.docgenApiUrl}/${this.api}/footer?footer_id=${id}`)
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
  
  saveHeader(data: any, name: string, status?: string) {
    return this.http
      .post<any>(`${environment.docgenApiUrl}/${this.api}/header`, {
        data: data,
        status: status,
        name: name
      })
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }  

  public getHeadersOrFooters(paginationData: PaginationDataModel, filterData?: any) {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: '/docgen',
    });
    const params: HttpParams = HttpUtils.getPaginationAndFilterParams(
      paginationData,
      filterData,
    );
    return this.http.get<any>(`${environment.docgenApiUrl}/${this.api}/all`, {
      headers,
      params,
    });
  }

  saveFooter(data: any,name:string,status?: string) {
    return this.http
      .post<any>(`${environment.docgenApiUrl}/${this.api}/footer`, {
        data: data,
        status:status,
        name: name
      })
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  delete(id: any) {
    return this.http
      .delete<any>(`${environment.docgenApiUrl}/${this.api}/${id}`)
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  copy(id: any) {
    return this.http
      .post<any>(`${environment.docgenApiUrl}/${this.api}/${id}`,{})
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  changeStatus(id: any, status) {
    return this.http
      .put<any>(`${environment.docgenApiUrl}/${this.api}/${id}/status`,{status: status})
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  editHeader(id: number, data: any, name: string, status?: string) {
    return this.http
      .put<any>(`${environment.docgenApiUrl}/${this.api}/header/${id}`, { data, status, name })
      .pipe(map((temp) => temp));
  }

  editFooter(id: number, data: any, name: string, status?: string) {
    return this.http
      .put<any>(`${environment.docgenApiUrl}/${this.api}/footer/${id}`, { data, status, name })
      .pipe(map((temp) => temp));
  }
}
