import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { PermissionService } from "src/app/shared/services/permissions/permission.service";

@Component({
  selector: "app-side-bar",
  templateUrl: "./side-bar.component.html",
  styleUrls: ["./side-bar.component.scss"],
})
export class SideBarComponent implements OnInit {
  @Output() isCollapsed = new EventEmitter<boolean>();
  @Input() isCollapsedOrExpand = false;

  constructor(private permissionService: PermissionService) {}

  ngOnInit() {}

  collapseOrExpand() {
    this.isCollapsedOrExpand = !this.isCollapsedOrExpand;
    this.isCollapsed.next(this.isCollapsedOrExpand);
  }

  hasPermission() {
    return this.permissionService.getPermission("docgen:list|docgen:manage");
  }
}
