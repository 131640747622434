import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { TemplateTypeService } from 'src/app/services/template-type.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CreateTemplateTypeDialogComponent } from "../create-template-type-dialog/create-template-type-dialog.component"
import { TableViewComponent } from 'src/app/shared/components/table-view/table-view.component';

@Component({
  selector: 'app-template-type-overview',
  templateUrl: './template-type-overview.component.html',
  styleUrls: ['./template-type-overview.component.scss']
})
export class TemplateTypeOverviewComponent implements AfterViewInit {
  @ViewChild(TableViewComponent, { static: false }) tableView;

  public columns = [
    {id: "name", name: "template-type.Name"},
    {id: "use_case", name: "template-type.UseCase"},
    {id: "created_at", name: "template-type.Created", type: "date", format: "dd/MM/yyyy"},
    {id: "created_by", name: "template-type.CreatedBy"},
    {id: "updated_at", name: "template-type.Updated", type: "date", format: "dd/MM/yyyy HH:mm"},
    {id: "updated_by", name: "template-type.UpdatedBy"},
    {id: "status", name: "template-type.Status"}, 
  ];
  public api = "template-type";
  public editRoute = null;
  public name = "template-type";

  constructor(
    public templateTypeService: TemplateTypeService,
    private router: Router,
    public dialog: MatDialog,
  ) {}

  ngAfterViewInit() {
  }

  ngOnInit() {
  }

  public editTemplateType(id) {
    const dialogRef = this.dialog.open(CreateTemplateTypeDialogComponent, {
      data: {mode:"edit", id: id},
      panelClass: 'custom-modalbox',
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.tableView.refreshTable();
      }
    });    
  }

  public createTemplateType() {
    const dialogRef = this.dialog.open(CreateTemplateTypeDialogComponent, {
      data: {mode:"create"},
      panelClass: 'custom-modalbox',
      backdropClass: 'cdk-overlay-transparent-backdrop',
      hasBackdrop: true,
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.tableView.refreshTable();
      }
    });    
  }
}
