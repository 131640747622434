import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateTypeService } from '../../../services/template-type.service';
import { TemplateType } from 'src/app/models/template-type';
import { VariableService } from '../../../services/variable.service';
import { TreeViewComponent } from 'src/app/shared/components/tree-view/tree-view.component';

export interface DialogData {
  id: number;
  mode: string;
}

@Component({
  selector: 'app-create-template-type-dialog',
  templateUrl: './create-template-type-dialog.component.html',
  styleUrls: ['./create-template-type-dialog.component.scss']
})
export class CreateTemplateTypeDialogComponent implements OnInit {
  @ViewChild(TreeViewComponent, { static: false }) treeView;
  name: string;
  useCase: string;
  templateType: TemplateType;
  id: number;

  constructor(public dialogRef: MatDialogRef<CreateTemplateTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private variableService: VariableService,
    private templateTypeService: TemplateTypeService
  ) { }

  ngOnInit() {
    this.useCase = "";
    this.templateType = new TemplateType();
    if (this.data.mode == 'edit') {
      this.id = this.data.id;
      this.getTemplateType(this.data.id);
    }
  }

  getTemplateType(id: any) {
    this.templateTypeService.get(id).subscribe(
      res => {
        this.name = res.name;
        this.useCase = res.use_case;
        this.treeView.setData(JSON.parse(res.variables));
      },
      error => {
      })
  }

  onConfirm(): void {
    this.templateType.id = this.id;
    this.templateType.name = this.name;
    this.templateType.use_case = this.useCase;
    this.templateType.variables = this.treeView.getData();

    if (this.data.mode == 'edit') {
      this.templateTypeService.update(this.id, this.templateType).subscribe(
        res => {
          this.dialogRef.close(true);
        },
        error => {
        })

    } else {
      this.templateTypeService.create(this.templateType).subscribe(
        res => {
          this.dialogRef.close(true);
        },
        error => {
        })
    }
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

  public importJSON(event: any) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      reader.readAsText(file);

      reader.onload = () => {
        let data = JSON.parse(<any>reader.result);
        this.variableService.generate(data).subscribe(
          res => {
            this.treeView.setData(res.tree);
            this.useCase = res.useCase;
          },
          error => {
          }
        )
      };
    }
  }
}
