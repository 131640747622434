import { Component, OnInit, Inject, ViewChild, Input, EventEmitter, Output, Injectable } from '@angular/core';
import {NestedTreeControl, FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeNestedDataSource, MatTreeFlattener, MatTreeFlatDataSource} from '@angular/material/tree';
import { VariableService } from 'src/app/services/variable.service';

export interface TreeViewData {
  name: string;
  ident: string;
  type: string;
  visible: boolean;
  children?: TreeViewData[];
} 

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent {
  @Input() isEditMode: boolean;
  @Input() conditionalToggled: boolean;

  @Output() messageEvent = new EventEmitter<string>();
    
  treeData: TreeViewData[];  

  treeControl = new NestedTreeControl<TreeViewData>(node => node.children);
  dataSource = new MatTreeNestedDataSource<TreeViewData>();

  constructor(
    private variableService: VariableService
  ) {}

  ngOnInit() {
    this.dataSource.data = [];
  }

  hasChild = (_: number, node: TreeViewData) => !!node.children && node.children.length > 0;

  generateData(data:TreeViewData[]) {
    let result:TreeViewData[] = [];
    for (let node of data) {
      if (node.visible) {
        let newNode:TreeViewData = {
          name: node.name,
          ident: node.ident,
          type: node.type,
          visible: node.visible
        }
        if(node.children)
          newNode.children = this.generateData(node.children);

        result.push(newNode);
      }
    }

    return result;
  }

  public setData(data) {
    if (!this.isEditMode) {
      this.dataSource.data = this.generateData(data);  
    
    } else
      this.dataSource.data = data;
  }

  public getData() {
    return JSON.stringify(this.dataSource.data);
  }

  toggleChildNode(node: TreeViewData, value) {
    node.visible = value;
    for (let child of node.children) {
      if (child.type === "Value")
        child.visible = value;
      else
        this.toggleChildNode(child, value);
    }
  }

  toggleNodeVisible(node) {
    node.visible = !node.visible;
    this.toggleChildNode(node, node.visible);
  }
  
  checkAllSelected(node) {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => child.visible);   
  }

  checkPartialSelected(node) {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => child.visible);
    node.visible = result; 
    return result && !this.checkAllSelected(node);    
  }

  toggleLeafVisible(node) {
    node.visible = !node.visible;
  }  

  onArrayClick(node) {
    this.messageEvent.emit(Object.assign(node, { conditional : this.conditionalToggled }));
  }

  onLeafClick(node) {
    this.messageEvent.emit(Object.assign(node, { conditional : this.conditionalToggled }));
  }
}
