import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PageConfig {
  format: string,        
  orientation: string,
  border: {
     top: string,
     bottom: string,
     left: string,
     right: string,
  },
  header: {
    height: string,
  },
  footer: {
    height: string,
  },
  renderDelay: number
} 


@Component({
  selector: 'app-page-setup-dialog',
  templateUrl: './page-setup-dialog.component.html',
  styleUrls: ['./page-setup-dialog.component.scss']
})
export class PageSetupDialogComponent implements OnInit {
   page : PageConfig

  constructor(public dialogRef: MatDialogRef<PageSetupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PageConfig
  ) {
    this.page = data;
  }

  ngOnInit() {
  }

  onConfirm(): void {
    this.dialogRef.close(this.page);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}
