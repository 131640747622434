import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Template } from "../models/template";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TemplateService {
  private api = "template";
  private currentUserSubject: BehaviorSubject<Template>;
  public template: Observable<Template>;

  constructor(private http: HttpClient) {}

  saveTemplate(template) {
    return this.http
      .post<any>(`${environment.docgenApiUrl}/template`, { template })
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  updateTemplate(template, id: any) {
    return this.http
      .put<any>(`${environment.docgenApiUrl}/template/${id}`, { template })
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  delete(id: any) {
    return this.http
      .delete<any>(`${environment.docgenApiUrl}/${this.api}/${id}`)
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  copy(id: any) {
    return this.http
      .post<any>(`${environment.docgenApiUrl}/${this.api}/${id}`, null)
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  changeStatus(id: any, status) {
    return this.http
      .put<any>(`${environment.docgenApiUrl}/template/${id}/status`, { status })
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  getTemplate(api: string, templateId: number) {
    return this.http
      .get<any>(`${environment.docgenApiUrl}/${api}/${templateId}`)
      .pipe(
        map((temp) => {
          return temp;
        })
      );
  }

  // for testing purpose
  generatePDF(data) {
    data.type = "pdf";
    return this.http
      .post(
        `${environment.docgenApiUrl}/docgen`,
        {
          data,
        },
        { responseType: "blob" }
      )
      .pipe();
  }

  generateHTML(data) {
    data.type = "html";
    return this.http
      .post<any>(`${environment.docgenApiUrl}/docgen`, { data })
      .pipe(
        map((temp) => {
          return temp.html;
        })
      );
  }
}
