import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TenantConfiguration } from "../models/tenant-configuration.model";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class TenantConfigurationService {
  private url = `${environment.tenantManagementApiUrl}`;

  constructor(private httpClient: HttpClient) {}

  public async getKeycloakConfiguration(): Promise<TenantConfiguration> {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const subdomain = window.location.origin.replace("docgen.", "");
    localStorage.setItem("currentRole", urlParams.get("currentRole"));

    // var re = /icom./gi;
    // const subdomain = window.location.origin.replace(re, "");

    return await new Promise((resolve, reject) => {
      this.httpClient
        .get(`${this.url}/configuration?subdomain=${subdomain}`)
        .subscribe((response: TenantConfiguration) => {
          resolve(response);
        });
    });
  }

  getTenantTimeZone() {
    const headers: HttpHeaders = new HttpHeaders({
      origin_path: "/tenants/timezone",
      entity_type: "Tenant",
    });
    return this.httpClient
      .get<any>(`${this.url}/timezone`, {
        headers,
      })
      .pipe(
        map((tenantData) => {
          localStorage.setItem("timezone", tenantData || "Europe/Amsterdam");
          return;
        })
      )
      .subscribe();
  }
}
